import React, { ReactElement } from "react"

interface Props {
  type: "text" | "phone" | "email" | "dob"
  className?: string
  value?: any
  onChange?: any
  placeholders?: string | string[]
  prefix?: string
}

function Input({
  type,
  value,
  placeholders,
  onChange,
  className,
  prefix,
}: Props): ReactElement {
  const [val, setVal] = React.useState("")
  const [dob1, setDOB1] = React.useState("")
  const [dob2, setDOB2] = React.useState("")
  const [dob3, setDOB3] = React.useState("")
  React.useEffect(() => {
    setVal(value)
  }, [value])
  const onChangeVal = (e: any) => {
    const v = e.target.value
    change(v)
    if (onChange) {
      onChange(v)
    }
  }
  const change = (v: any) => {
    setVal(v)
  }

  const onChangeDOB = (part: string) => (e: any) => {
    const v = e.target.value
    if (part === "dob1") {
      setDOB1(v + "")
    }
    if (part === "dob2") {
      setDOB2(v + "")
    }
    if (part === "dob3") {
      setDOB3(v + "")
    }
  }

  React.useEffect(() => {
    if (onChange) {
      onChange(dob1 + "-" + dob2 + "-" + dob3)
    }
  }, [dob1, dob2, dob3])

  const ph = Array.isArray(placeholders) ? placeholders[0] : placeholders
  const phs = placeholders || []
  return (
    <div className="relative">
      {type === "text" ? (
        <input
          className={
            className + " " + " p-2 w-full h-12 rounded-md border border-2"
          }
          onChange={onChangeVal}
          placeholder={ph}
          value={val}
        />
      ) : type === "phone" ? (
        <>
          {prefix && prefix.length ? (
            <div className="absolute left-0 text-gray-500 h-full flex items-center pl-2">
              {prefix}
            </div>
          ) : (
            ""
          )}
          <input
            className={
              className +
              (prefix && prefix.length?" pl-10 ":"") +
              " p-2 w-full h-12 rounded-md border border-2"
            }
            pattern="\d*"
            type="tel"
            onChange={onChangeVal}
            placeholder={ph}
            value={val}
          />
        </>
      ) : type === "dob" ? (
        <div className=" flex-1 flex  flex-row justify-start">
          <div>
            <input
              className={
                (className || "") +
                " " +
                " p-2 w-full h-12 rounded-md border border-2"
              }
              pattern="\d*"
              onChange={onChangeDOB("dob1")}
              placeholder={phs[0]}
              type="tel"
              value={dob1}
            />
          </div>
          <div>
            <input
              className={
                (className || "") +
                " " +
                " p-2 w-full h-12 rounded-md border border-2"
              }
              pattern="\d*"
              onChange={onChangeDOB("dob2")}
              placeholder={phs[1]}
              type="tel"
              value={dob2}
            />
          </div>
          <div>
            <input
              className={
                (className || "") +
                " " +
                " p-2 w-full h-12 rounded-md border border-2"
              }
              pattern="\d*"
              onChange={onChangeDOB("dob3")}
              placeholder={phs[2]}
              type="tel"
              value={dob3}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Input

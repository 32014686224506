
import { Link } from 'gatsby';
import React from 'react';
import Button from '../../components/button';
import Slider, { Handle, SliderTooltip } from '../../components/slider';
import Utils from '../../utils';
const styles = require("./index.module.css");
type Props = {
    hideButton?:boolean
}

const handle = ({ sign }: any) => props => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value} ${sign || "€"}`}
            visible={dragging}
            placement="top"
            zIndex={10}
            key={index}
            overlayInnerStyle={{
                fontSize: "24px",
                padding: "10px",
                marginTop: "-40px",
                height: "40px",
                backgroundColor: "#fff",
                color: "#000",
            }}
        >
            <Handle value={value}  {...restProps} style={{
                width: "24px",
                height: "24px",
                marginTop: "-10px",
            }} />
        </SliderTooltip>
    );
};
function EMICalculator({hideButton}: Props) {
    const [amount, setAmount] = React.useState(5000);
    const [interest, setInterest] = React.useState(9.95);
    const [duration, setDuration] = React.useState(24);

    const onChange = (field: string) => (value: number) => {
        switch (field) {
            case "amount":
                setAmount(value);
                break;
            case "interest":
                setInterest(value);
                break;
            case "duration":
                setDuration(value);
                break;
        }
    }
    const r = (amount * (interest * 0.01)) / duration;
    const emi = ((amount / duration) + r).toFixed(2);
    return (


        <div className="  p-8 rounded-lg w-full" style={{ minWidth: "300px" }}>
            <h3 className='mb-8 text-2xl font-bold'>EMI-Rechner</h3>
            <div className='mb-8'>
                <h5>Ich möchte konsolidieren</h5>
                <strong className=' text-2xl'>€<span>{amount}</span></strong>
                <Slider onChange={onChange("amount")} min={1000} max={50000} step={1000} value={amount} handle={handle({ sign: "EUR" })} />
            </div>
            <div className='mb-8'>
                <h5>Für eine Dauer von</h5>
                <strong className=' text-2xl '><span>{duration}</span> Monate</strong>
                <Slider onChange={onChange("duration")} min={2} max={84} step={1} value={duration} handle={handle({ sign: 'mo' })} />
            </div>
            {/* <div className={styles.field}>
                        <h5>Zinssatz ( {interest}% )</h5>
                        <Slider onChange={onChange("interest")} min={11} max={18} step={1} value={interest} handle={handle({ sign: '%' })} />
                    </div> */}
            <div className='mb-8'>

                <h2 className='text-2xl font-bold'>
                Monatliche EMI : €<span>{emi}</span>
                </h2>
            </div>

        </div>


    )
}

export default EMICalculator
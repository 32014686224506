import React from 'react'
import _Slider, { SliderProps, SliderTooltip as _SliderTooltip, Handle as _Handle } from 'rc-slider'
import 'rc-slider/assets/index.css';
interface Props extends SliderProps { }

function Slider(props: Props) {
    return (
        <_Slider {...props} />
    )
}

export function SliderTooltip(props: any) {
    return (
        <_SliderTooltip {...props} />
    )
}

export function Handle(props: any) {
    return (
        <_Handle {...props} />
    )
}

export default Slider
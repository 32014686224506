import React, { ReactElement } from "react"
import Slider from "react-rangeslider"
import "./styles.css"
import "react-rangeslider/lib/index.css"
interface Props {
  value: number
  onChange?: any
  prefix?: string
  min?: number
  max?: number
  step?: number
}

function RangeSlider({
  value,
  onChange,
  prefix,
  min = 1,
  max = 100,
  step = 1,
}: Props): ReactElement {
  const [val, setVal] = React.useState(0)

  React.useEffect(() => {
    setVal(val)
  }, [value])

  const onValChange = (v: number) => {
    if (onChange) {
      setVal(v)
      onChange(v)
    }
  }

  const format = (v: number) => {
    return prefix ? prefix + " " + v : v
  }
  return (
    <div className={" w-full"}>
      <Slider
        format={format}
        value={val}
        min={min}
        onChange={onValChange}
        max={max}
        step={step}
      />
    </div>
  )
}

export default RangeSlider

import React, { ReactElement } from "react"
import RangeSlider from "../../components/range"
import Select from "../../components/select"
import Input from "../../components/input"

interface IFormField {
  type?: string
  subType?: any
  label?: string
  prefix?: string
  suffix?: string
  name: string
  min?: number
  max?: number
  step?: number
  options?: any
  required?: boolean
  placeholder?: string | string[]
  valueVisible?: boolean
  defaultValue?: any
  test?: "email" | "phone"
  display?: "horizontal" | "vertical"
  spread?: boolean
  minLength?: number
  visible?: boolean
}

interface Props {
  onApply?: any
  schema?: IFormField[]
  loading?: boolean
}

function ApplicationForm({
  onApply,
  schema = [],
  loading = false,
}: Props): ReactElement {
  const [entity, setEntity] = React.useState({} as any)
  const [errors, setErrors] = React.useState({} as any)

  React.useEffect(() => {
    const _entity: any = {}
    for (const field of schema) {
      if (!field.name) break
      _entity[field.name] = field.defaultValue
    }
    setEntity({ ..._entity })
  }, [schema])

  const onValueChange = (key: string) => (val: any) => {
    const _entity = { ...entity }
    _entity[key] = val
    setEntity({ ..._entity })
  }

  const onApplyClick = (e: any) => {
    e.preventDefault()
    if (onApply && validate(entity)) {
      const ref = window.location.pathname
      onApply({ ...entity, referer: (entity["referer"] || "") + "::" + ref })
    }
  }

  const validate = (e: any) => {
    const _errors = { ...errors }
    let ret = 0
    for (const field of schema) {
      const { required, test, name, minLength } = field
      if (!name) {
        break
      }

      // required validation
      if (required && !e[name]) {
        ret += 1
        _errors[name] = "Erforderlich"
        if (ret === 1) {
          window.location.href = "#" + "__field_" + name
        }
        continue
      }
      // minlength validation
      if (minLength && e[name].length < minLength) {
        ret += 1
        _errors[name] = "Minimale Länge : " + minLength
        if (ret === 1) {
          window.location.href = "#" + "__field_" + name
        }
        continue
      }
      if (test) {
        window.console.log(name, test)
        let regex
        if (test === "email") {
          regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        } else if (test === "phone") {
          // regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
          regex = /^[0-9]*$/
        }
        if (regex && !regex.test(e[name])) {
          ret += 1
          _errors[name] = "Ungültiges Format"
          if (ret === 1) {
            window.location.href = "#" + "__field_" + name
          }
          continue
        }
      }

      delete _errors[name]
    }
    if (ret <= 0) {
      setErrors({})
    } else {
      setErrors({ ..._errors })
    }
    return ret <= 0
  }

  return (
    <div
      style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
      className=" flex flex-col h-full w-full min-w-100 p-10 shadow-xl rounded-lg"
    >
      {/* {JSON.stringify(entity)} */}
      {schema.map((field: IFormField, index: number) => {
        if (!field.name || field.visible === false) return ""
        return (
          <div key={index} className="relative" id={"__field_" + field.name}>
            {field.type === "range" ? (
              <div className="flex-1">
                <div className="p-4 text-center">
                  {errors && errors[field.name] ? (
                    <span className="inline-block bg-red-400 mr-2 rounded text-white px-2 text-xs">
                      ! {errors[field.name]}
                    </span>
                  ) : (
                    ""
                  )}
                  <strong>
                    {field.label} : {field.prefix}{" "}
                    {field.name ? entity[field.name] : ""}
                  </strong>
                </div>
                <RangeSlider
                  min={field.min || 0}
                  max={field.max || 100}
                  step={field.step || 1}
                  prefix={field.prefix}
                  value={entity[field.name] || field.defaultValue || 10}
                  onChange={onValueChange(field.name)}
                />
              </div>
            ) : field.type === "select" ? (
              <div
                className={
                  "flex-1 py-4 " +
                  (field.display === "horizontal" ? "flex items-center" : "")
                }
              >
                <div
                  className={
                    (field.display === "horizontal" ? "" : "p-4") +
                    " text-center"
                  }
                >
                  <strong>
                    {errors && field.name && errors[field.name] ? (
                      <span className="inline-block bg-red-400 mr-2 rounded text-white px-2 text-xs">
                        ! {errors[field.name]}
                      </span>
                    ) : (
                      ""
                    )}
                    {field.label}{" "}
                    {field.valueVisible ? (
                      <span>
                        :&nbsp;{field.name ? entity[field.name] : ""}{" "}
                        {field.suffix}
                      </span>
                    ) : (
                      ""
                    )}
                  </strong>
                </div>
                <div>
                  <Select
                    spread={field.spread}
                    value={entity[field.name] || field.defaultValue}
                    options={field.options}
                    onChange={onValueChange(field.name)}
                  />
                </div>
              </div>
            ) : field.type === "input" ? (
              <div className="flex-1 flex flex-col justify-start items-stretch">
                <div className="py-4">
                  <strong>
                    {field.label}
                    {field.required ? "*" : ""}
                  </strong>
                  {errors && field.name && errors[field.name] ? (
                    <span className="inline-block bg-red-400 ml-2 rounded text-white px-2 text-xs">
                      ! {errors[field.name]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex-1">
                  <Input
                    onChange={onValueChange(field.name)}
                    value={entity[field.name] || field.defaultValue}
                    type={field.subType || "text"}
                    placeholders={field.placeholder}
                    prefix={field.prefix}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )
      })}

      {onApply ? (
        <div className="flex justify-center mt-8 ">
          <button
            disabled={loading}
            onClick={onApplyClick}
            className="flex justify-center items-center bg-blue-700 hover:bg-blue-500 p-4 text-white rounded shadow-lg"
          >
            <span>Jetzt beantragen</span>
            {loading ? (
              <span className="ml-2">
                <img
                  className="w-4 h-4"
                  src={require("../../images/loading.gif").default}
                />
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ApplicationForm

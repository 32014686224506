import React, { ReactElement } from "react"

interface Props {
  children?: any
  type?: "primary" | "secondary"
  className?: string
  onClick?: any
}

function Button(props: Props): ReactElement {
  const { children, type = "primary", className, onClick } = props
  return (
    <button
      className={
        "px-8 py-4 rounded-md outline-none " +
        (type === "primary" ? "bg-blue-900 " : "bg-blue-500 ") +
        (type === "primary" ? "hover:bg-blue-800 " : "hover:bg-blue-400 ") +
        className
      }
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button

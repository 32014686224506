import React, { ReactElement } from "react"

interface IOption {
  value: any
  title?: string
}

interface Props {
  options?: IOption[]
  onChange?: any
  value?: any
  spread?: boolean
}

function Select({
  options = [],
  value,
  onChange,
  spread = false,
}: Props): ReactElement {
  const [val, setVal] = React.useState()
  React.useEffect(() => {
    setVal(value)
  }, [value])
  const onOptionClick = (v: any) => (e: any) => {
    choose(v)
    if (onChange) {
      onChange(v)
    }
  }
  const choose = (v: any) => {
    setVal(v)
  }

  const changed = (e: any) => {
    const v = e.target.value
    choose(v)
    if (onChange) {
      onChange(v)
    }
  }

  return (
    <div>
      {spread ? (
        <div className="flex flex-row flex-wrap justify-center">
          {options.map((opt: IOption, index: number) => (
            // <div
            //   key={index}

            //   className=" p-2 flex justify-center"
            // >
            <div
              style={{ minWidth: "70px" }}
              onClick={onOptionClick(opt.value)}
              className={
                "cursor-pointer p-2 m-2 h-16 rounded flex justify-center items-center  " +
                (val === opt.value
                  ? "bg-blue-800 text-white"
                  : "bg-gray-300 text-black")
              }
            >
              {opt.title || opt.value}
            </div>
            // </div>
          ))}
        </div>
      ) : (
        <select
          onChange={changed}
          className="px-2 m-2 w-full py-2 bg-gray-300 rounded-md"
        >
          {options.map((opt: IOption, index: number) => (
            <option value={opt.value}>{opt.title || opt.value}</option>
          ))}
        </select>
      )}
    </div>
  )
}

export default Select
